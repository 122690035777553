<template>
  <b-modal
      ref="add-goldbargoal-modal"
      size="lg"
      title="Add gold bar goal"
  >
    <b-form-group label="Name">
      <b-form-input
          v-model="form.name"
          placeholder="name"
          required
          :state="!$v.form.name.$dirty ? null :(!$v.form.name.$error)"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Expiration">
      <b-form-input
          v-model="form.expiration"
          placeholder="expiration"
          required
          :state="!$v.form.expiration.$dirty ? null :(!$v.form.expiration.$error)"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Status">
      <b-form-select
          required
          v-model="form.status"
          :options="options.status"
          :state="!$v.form.status.$dirty ? null :(!$v.form.status.$error)"
      ></b-form-select>
    </b-form-group>
    <b-form-group label="Gold">
      <b-form-input
          v-model="form.gold"
          placeholder="gold"
          required
          :state="!$v.form.gold.$dirty ? null :(!$v.form.gold.$error) && form.gold >= 0"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Bonus">
      <b-form-input
          v-model="form.bonus"
          placeholder="bonus"
          required
          :state="!$v.form.bonus.$dirty ? null :(!$v.form.bonus.$error) && form.bonus >= 0"
      ></b-form-input>
    </b-form-group>
    <template #modal-footer>
      <div class="w-100">
        <div class="buttons float-right">
          <b-button type="button" variant="secondary" class="mr-1" @click="hideModal()">{{ $t('btn.cancel') }}
          </b-button>
          <b-button variant="primary" :disabled="busy" @click="submit()">
            <div v-if="busy">
              <b-spinner small type="grow"></b-spinner>
              {{ $t('btn.state.saving') }}
            </div>
            <div v-else>
              {{ $t('btn.save') }}
            </div>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>

import {decimal, required} from "vuelidate/lib/validators";
import alertify from "alertifyjs";
import {mapState} from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      id: null,
      busy: false,
      title: null,
      groups: [],
      serverError: null,
      form: {
        id: null,
        name: null,
        gold: null,
        bonus: null,
        expiration: null,
        status: null,
      },
      options: {
        status: [
          'active',
          'draft'
        ]
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      expiration: {
        required,
        decimal
      },
      gold: {
        required,
        decimal
      },
      bonus: {
        required,
        decimal
      },
      status: {
        required
      },
    }
  },
  mounted() {
    this.openModal();
  },
  computed: {
    ...mapState({
      item: (state) => state.goldBarGoalStore.item,
    }),
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let url = '';
        if(this.form.id === null){
          url = 'goldBarGoalStore/new';
        }else{
          url = 'goldBarGoalStore/update';
        }
        this.$store.dispatch(url, {...this.form})
            .then(() => {
              this.hideModal();
              setTimeout(() => {
                this.$emit('reload-items')
              }, 1000)
            })
            .catch((error) => {
              console.log('error');
              console.log(error);
              if (error.response.status === 400) {
                alertify.error(error.response.data.label);
              }

            })
      }
    },
    openModal() {
      this.$refs['add-goldbargoal-modal'].show();
    },
    openEditModal(id) {
      this.form.id = id;
      this.$refs['add-goldbargoal-modal'].show();
      axios.get(`/gold-bar-goal/${id}/details`)
          .then((response) => {
            this.form.name = response.data.name;
            this.form.gold = response.data.gold;
            this.form.bonus = response.data.bonus;
            this.form.expiration = response.data.expiration;
            this.form.status = response.data.status;
          })
          .catch((error) => {
            console.log('error');
            console.log(error);

          })

    },
    hideModal() {
      this.$refs['add-goldbargoal-modal'].hide();
    },
  }
}
</script>
