<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
    </page-header>
      <page-card>
      <template v-slot:header>
        {{ $t('page.title.gold-bar-goal-list') }}
          <b-button @click="openModal" size="sm" variant="outline-success">{{ $t('btn.add') }}</b-button>
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-3">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">



                  <template #cell(price)="data">
                    <span style="float: right">{{ data.value }}</span>
                  </template>
                  <template #cell(status)="data">
                    <b-badge>{{ data.value }}</b-badge>
                  </template>
                  <template #cell(isStarterPack)="data">
                    <b-badge :variant="data.value ? 'success' : 'danger'">{{ data.value ? 'Yes' : 'No' }}</b-badge>
                  </template>

                  <template #cell(actions)="data">
                    <div class="table-action--cell">
                      <b-button variant="primary" size="sm"
                                @click="editModal(data.item.id)">
                        Edit
                      </b-button>
                      <b-button v-if="data.item.status !== 'deleted'" variant="danger" size="sm"
                                @click="deleteItem(data.item.id)">
                        Delete
                      </b-button>
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";
import axios from "axios";
import GoldBarGoalNewModal from "@/modules/gold-bar-goal/containers/GoldBarGoalNewModal.vue";
import Vue from "vue";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,
  },
  data() {
    return {
      statuses: ['active', 'draft', 'deleted'],
      orderColumn: null,
      orderDirection: false,
      fields: [
        {key: 'id', label: this.$t('table.header.id')},
        {key: 'name', label: this.$t('table.header.name')},
        {key: 'gold', label: this.$t('table.header.gold')},
        {key: 'bonus', label: this.$t('table.header.bonus')},
        {key: 'status', label: this.$t('table.header.status')},
        {key: 'actions', label: ''},
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Akcje masowe'}
        ],
      },
      filterQuery: [],
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.loadItems();
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  computed: {
    ...mapState({
      perPage: (state) => state.goldBarGoalStore.perPage,
      currentPage: (state) => state.goldBarGoalStore.currentPage,
      items: (state) => state.goldBarGoalStore.items,
      isBusy: (state) => state.goldBarGoalStore.isBusy,
      totalCount: (state) => state.goldBarGoalStore.totalCount,
    }),
    filters: function () {
      return this.$store.getters["root/filter"]('miningPool');
    },
  },
  methods: {
    storeFilter(data) {
      this.$store.commit('root/setFilter', {storeName: 'goldBarGold', ...data})
    },
    createSortQuery() {
      let sorting;
      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }
        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    setPerPage(page) {
      this.setPerPage = page;
    },
    loadItems() {
      this.$store.dispatch('goldBarGoalStore/loadItems', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage,
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },
    changePage: function(currentPage) {
      this.$store.commit('goldBarGoalStore/setCurrentPage', currentPage);
    },
    applyFilters: function () {
      this.changePage(1);
      this.loadItems();
    },
    resetFilters() {
      this.filterQuery = [];
      this.$root.$emit('resetFilters');
      this.initFilters();
      this.applyFilters();
    },
    initFilters() {
      this.$store.commit('root/initFilters', {
        storeName: 'goldBarGold',
        filterNames: ['date', 'profit']
      });
    },
    paginationReload(data) {
      this.changePage(data.currentPage);
      this.$store.commit('goldBarGoalStore/setPerPage', data.perPage);
      this.loadItems();
    },
    openModal() {
      const ModalConstructor = Vue.extend(GoldBarGoalNewModal);
      const modal = new ModalConstructor({parent: this});
      modal.$on('reload-items', () => {
        this.loadItems();
      });
      modal.$mount();
      modal.openModal();
    },
    editModal(id) {
      const ModalConstructor = Vue.extend(GoldBarGoalNewModal);
      const modal = new ModalConstructor({parent: this});
      modal.$on('reload-items', () => {
        this.loadItems();
      });
      modal.$mount();
      modal.openEditModal(id);
    },
    deleteItem(id) {
      axios.post(`/gold-bar-goal/offer/${id}/delete`)
          .then(() => {
            setTimeout(() => {
              this.loadItems();
            }, 1000)
          })
          .catch((error) => {
            console.log('error');
            console.log(error);

          })
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function() {

    }
  }
}
</script>


